import React, {useState} from 'react'
import axios from "axios";
const AddCatPage = () => {
    const [servicename,setservicename]=useState(""); 
const submitThis = async (e) => {
    console.log(servicename);
  const post = { servicename: servicename }
  try {
    const res = await axios.post('https://api.stywork.in/SaveCat', post)
    console.log(res.statusText);
    console.log(res.status);
    if(res.statusText==="OK"){
      alert('New Category Created successfully!!!!');
      window.location.reload();
    }

    //else if(res.statusText==="OK"){}
  } catch (e) {
  if(e.response.status===409){
    alert('Category already created!!!!');
    window.location.reload();
  }else{
    alert('Some thing Wrong!!!!');
    window.location.reload();  
}
  }
}

const handleClickEvent = (event) => {
  event.preventDefault();
  submitThis();
}
    return (
      <>
      {/* Query section */}
      <section className="professional_section contact_section layout_padding form_section">
        <div className='container'>
          <div className="row" style={{ margin: "0 auto;" }}>
            <div className="col-lg-7 col-md-12 enqry_form_cont">
              <div className="detail-box">
                <h2>Add New Service</h2>
                <form action="" method="post" role="form" className="php-email-form" onSubmit={submitThis}>
              <div className="row">
                <div className="col-md-12 form-group">
                  Enter Service Name
                  <input type="text" name="servicename" className="form-control" id="servicename" 
                  required onChange={(e)=>setservicename(e.target.value)}/>
                </div>
              </div>        
              <div className="col-md-12 text-center p-3">
                <button type="submit" onClick={handleClickEvent} 
                className='btn btn-primary btn_design'>Submit</button></div>
            </form>
              </div>
            </div>
          </div>
        </div>
      </section >
      {/* end about section */}
        </>
    );
};
 
export default AddCatPage;