import React from "react";

const Ourwork = () => {
    return (
        <>
        
            {/* about section */}
            <section className="about_section layout_padding">
                <h1 className="text-center">Our Works</h1>
                <div class="col-md-12">
                    <div className="row">
                        <div class="col-md-4 col-sm-12 video_main_cont">
                            <div className="video_cont">
                                <video controls >
                                    <source src="/work_video/interoir_1.mp4" type="video/mp4" />
                                </video>
                            </div>
                            <div className="video_caption">
                                <h3>Interior, Noida</h3>
                            </div>
                        </div>
                        <div class="col-md-4  col-sm-12 video_main_cont">
                            <div className="video_cont">
                                <video  controls >
                                    <source src="/work_video/interior_2.mp4" type="video/mp4" />
                                </video>
                            </div>
                            <div className="video_caption">
                                <h3>Interior, Ghaziabad</h3>
                            </div>
                        </div>
                        <div class="col-md-4  col-sm-12 video_main_cont">
                            <div className="video_cont">
                                <video controls >
                                    <source src="/work_video/interior_3.mp4" type="video/mp4" />
                                </video>
                            </div>
                            <div className="video_caption">
                                <h3>Interior, Delhi</h3>
                            </div>
                        </div>
                        <div class="col-md-4  col-sm-12 video_main_cont">
                            <div className="video_cont">
                                <video controls >
                                    <source src="/work_video/interior_4.mp4" type="video/mp4" />
                                </video>
                            </div>
                            <div className="video_caption">
                                <h3>Interior, Raj Nagar Extension</h3>
                            </div>
                        </div>
                        <div class="col-md-4  col-sm-12 video_main_cont">
                            <div className="video_cont">
                                <video controls >
                                    <source src="/work_video/interior_5.mp4" type="video/mp4" />
                                </video>
                            </div>
                            <div className="video_caption">
                                <h3>Interior, Noida</h3>
                            </div>
                        </div>
                        <div class="col-md-4  col-sm-12 video_main_cont">
                            <div className="video_cont">
                                <video controls >
                                    <source src="/work_video/interior_6.mp4" type="video/mp4" />
                                </video>
                            </div>
                            <div className="video_caption">
                                <h3>Interior, Greater Noida</h3>
                            </div>
                        </div> </div>
                </div>
            </section>
        </>
    );
};

export default Ourwork;