import React from 'react';

const Contact = () => {
  return (
    <>
      {/* contact section */}
      <section className="contact_section layout_padding">
        <div className="container">
          <div className="heading_container">
            <h2>Contact Us</h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <form action="">
                <div>
                  <input type="text" placeholder="Name" />
                </div>
                <div>
                  <input type="text" placeholder="Phone Number" />
                </div>
                <div>
                  <input type="email" placeholder="Email" />
                </div>
                <div>
                  <input
                    type="text"
                    className="message-box"
                    placeholder="Message"
                  />
                </div>
                <div className="d-flex ">
                  <button>SEND</button>
                </div>
              </form>
            </div>
            <div className="col-md-6">
              <div className="map_container">
                <div className="map">
                  <div className="mapouter">
                    <div className="gmap_canvas">
                      <iframe
                        src="https://maps.google.com/maps?q=raj%20nagar%20extension,%20Ghaziabad,%20Uttar%20Pradesh,%20India,%20201017&t=&z=14&ie=UTF8&iwloc=&output=embed"
                        frameBorder={0}
                        scrolling="no"
                        style={{ width: 550, height: 450 }}
                      />
                      <style
                        dangerouslySetInnerHTML={{
                          __html:
                            ".mapouter{position:relative;height:30px;width:550px;background:#fff;} .maprouter a{color:#fff !important;position:absolute !important;top:0 !important;z-index:0 !important;}"
                        }}
                      />
                      <a href="https://blooketjoin.org/">blooket</a>
                      <style
                        dangerouslySetInnerHTML={{
                          __html:
                            ".gmap_canvas{overflow:hidden;height:450px;width:550px}.gmap_canvas iframe{position:relative;z-index:2}"
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end contact section */}
      {/* info section */}
      <section className="info_section ">
        <div className="container">
          <h4>Get In Touch</h4>
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="info_items">
                <div className="row">
                  <div className="col-md-4">
                    <a href="">
                      <div className="item ">
                        <div className="img-box ">
                          <i className="fa fa-map-marker" aria-hidden="true" />
                        </div>
                        <p>Raj Nagar Extension, Ghaziabad-201017</p>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-4">
                    <a href="">
                      <div className="item ">
                        <div className="img-box ">
                          <i className="fa fa-phone" aria-hidden="true" />
                        </div>
                        <p>+91 7827990083</p>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-4">
                    <a href="">
                      <div className="item ">
                        <div className="img-box">
                          <i className="fa fa-envelope" aria-hidden="true" />
                        </div>
                        <p>stywork05@gmail.com</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="social-box">
          <h4>Follow Us</h4>
          <div className="box">
            <a href="">
              <i className="fa fa-facebook" aria-hidden="true" />
            </a>
            <a href="">
              <i className="fa fa-twitter" aria-hidden="true" />
            </a>
            <a href="">
              <i className="fa fa-youtube" aria-hidden="true" />
            </a>
            <a href="">
              <i className="fa fa-instagram" aria-hidden="true" />
            </a>
          </div>
        </div>
      </section>
      {/* end info_section */}
    </>

  );
};

export default Contact;