import React, {useState, useEffect } from 'react';
import ReactSwitch from 'react-switch';
import { NavLink, Link } from "react-router-dom";
import axios from "axios";
const ShowhideCat = () => {
    const [_catList, CatData] = useState([]);
    //const [delid, setDelCatid] = useState();
    const [delid, setDelCatid] = useState();
    const [checked, setChecked] = useState(true);
    const loadData = async () => {
        const response = await axios.get("https://api.stywork.in/maincat");
        console.log(response.data);
        CatData(response.data);
    };
      const handalDelid=(val)=>{
        console.log(val);
        //alert(val);
        setDelCatid(val);
        deldata(val);
        alert('Record Deleted successfully!!!!');
      }
      const deldata= async (catid) => {
        console.log(catid);
        const post={catid:catid};
        console.log(post);
        const response = await axios.post("https://api.stywork.in/delcat",post);
        console.log(response.data);
        //CatData(response.data);
    };
        const handleChange = val => {
          setChecked(val)
        }
    useEffect(() => {
        loadData();
    }, []);
    var j=1;
    return (
        <>
            <h1 className='heding_design'>Category List</h1>
            <div className='rows'>
                <div className='col-md-12'>
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th>Sr No.</th>
                                <th>Category Name</th>
                                <th>View</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {_catList.length > 0 && (
                                _catList.map(catitems => (
                                    <tr>
                                        <td>{j++}</td>
                                        <td>{catitems.catname}</td>
                                        <td><Link id={catitems.id} to={"/SubCatList/"+catitems.id}>View</Link></td>
                                        <td><ReactSwitch
        checked={checked}
        onChange={handleChange}
      />
      <button onClick={(e)=>handalDelid(catitems.id)} id="btn_Del" className='del_btn_design'><i className='fa fa-trash '></i></button>
      {/* <Link className='del_btn_design' ><i className='fa fa-trash '></i></Link> */}
      </td></tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
};

export default ShowhideCat;