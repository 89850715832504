import React, {useState, useEffect } from 'react';
import ReactSwitch from 'react-switch';
import { NavLink, Link,useParams } from "react-router-dom";
import axios from "axios";
const ShowhideCat = () => {
    const { catid } = useParams("");
    console.log(catid);
    const [_subcatList, SubCatData] = useState([]);
    const [checked, setChecked] = useState(true);
    const loadData = async () => {
        const post = { mastercatid: catid};
        const response = await axios.post("https://api.stywork.in/cat2",post);
        console.log(response.data);
        SubCatData(response.data);
    };
      const handalDelid=(val)=>{
        console.log(val);
       // alert(val);
        deldata(val);
        alert('Record Deleted successfully!!!!');
      }
      const deldata= async (subcatid) => {
        console.log(subcatid);
        const post={subcatid:subcatid};
        console.log(post);
        const response = await axios.post("https://api.stywork.in/delsubcat",post);
        console.log(response.data);
        //CatData(response.data);
    };
        const handleChange = val => {
          setChecked(val)
        }
    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <h1 className='heding_design'>Sub Category List</h1>
            <div className='rows'>
                <div className='col-md-12'>
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th>Sr No.</th>
                                <th>Sub Category Name</th>
                                <th>View</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {_subcatList.length > 0 && (
                                _subcatList.map(catitems => (
                                    <tr>
                                        <td>{catitems.id}</td>
                                        <td>{catitems.subcatname}</td>
                                        <td><Link id={catitems.id} to={"/MicroCatList/"+catitems.id}>View</Link></td>
                                        <td><ReactSwitch
        checked={checked}
        onChange={handleChange}
      />
      <button onClick={(e)=>handalDelid(catitems.id)} id="btn_Del" className='del_btn_design'><i className='fa fa-trash '></i></button>
      </td></tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
};

export default ShowhideCat;