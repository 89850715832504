import React, {useState, useEffect } from 'react';
import ReactSwitch from 'react-switch';
import { NavLink, Link,useParams } from "react-router-dom";
import axios from "axios";
const ShowhideCat = () => {
    const [_catList, CatData] = useState([]);
    const [checked, setChecked] = useState(true);
    const { subcatid } = useParams("");
    console.log(subcatid);
    const loadData = async () => {
        const post = { subcatid: subcatid};
        const response = await axios.post("https://api.stywork.in/cat3",post);
        console.log(response.data);
        CatData(response.data);
    };
      const handalDelid=(val)=>{
        console.log(val);
      }
        const handleChange = val => {
          setChecked(val)
        }
    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <h1 className='heding_design'>Micro Category List</h1>
            <div className='rows'>
                <div className='col-md-12'>
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th>Sr No.</th>
                                <th>Micro Category Name</th>
                                {/* <th>View</th> */}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {_catList.length > 0 && (
                                _catList.map(catitems => (
                                    <tr>
                                        <td>{catitems.id}</td>
                                        <td>{catitems.microcatname}</td>
                                        {/* <td><Link id={catitems.id} to={""}>View</Link></td> */}
                                        <td><ReactSwitch
        checked={checked}
        onChange={handleChange}
      />
      <Link className='del_btn_design' onClick={(e)=>handalDelid(e.target.value)} id={catitems.id}><i className='fa fa-trash '></i></Link>
      </td></tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
};

export default ShowhideCat;