import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
const Adminlogin = () => {
  const [username,setUsername]=useState(""); 
  const [users,setData]=useState([]);
  const navigate = useNavigate();
const [passw,setPassw]=useState(""); 
const submitThis = async (e) => {
  const post = { username: username }
  try {
    const res = await axios.post('https://api.stywork.in/adminLogin', post)
    console.log(res.data);
    setData(res.data);
    const username= users[0].username;
    const userole= users[0].role;
    const userid= users[0].userid;
    console.log(users[0].username);
    console.log(userole);
    console.log(userid);
    localStorage.setItem('username',username ); //changed
    localStorage.setItem('userole',userole ); //changed
    localStorage.setItem("userid",userid);
    window.location.href="/AdminDash";
    //window.location.reload(false);
    //return navigate('/AdminDash');
  } catch (e) {
  console.log(e);
  }
}
// useEffect(()=>{
//   submitThis();
// },[]);
const handleClickEvent = (event) => {
  event.preventDefault();
  submitThis();
}
    return (
      <>
        {/* Query section */}
      <section className="professional_section contact_section layout_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <div className="img-box">
                <img src="images/dining_hall_room.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div className="detail-box">
                <h2>Admin Login</h2>
                <form action="" method="post" role="form" className="php-email-form" onSubmit={submitThis}>
                  <div>
                  <input type="text" name="username" className="form-control" id="username" 
                  placeholder="Enter User Name or Email Id" required value={username} onChange={(e)=>setUsername(e.target.value)}/>
                </div>
                <div>
                  <input type="password" className="form-control" name="passw" id="passw" 
                  placeholder="Enter your password" required value={passw} onChange={(e)=>setPassw(e.target.value)}/>
                </div>
                  <div className="d-flex ">
                    <button type="submit" onClick={handleClickEvent}>LOGIN</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end query section */}
        </>
    );
};
 
export default Adminlogin;