import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
const EnqryForm = () => {
  const [subCat, setubCatData] = useState([]);
  const [microCat, microCatList] = useState([]);
  const [name, setUsername] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [ratestatus, setratestatus] = useState();
  const [area, setArea] = useState();
  const [price, setPrice] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [condition, setCondition] = useState();
  const [microcatid, Setmicrocatid] = useState([]);
  const { subcatid } = useParams();
  const navigate = useNavigate();
  const loadData = async (val) => {
    const post = { microcatid: val }
    console.log(post);
    Setmicrocatid(val);
    const response = await axios.post("https://api.stywork.in/reqform", post);
    console.log(response.data);
    setubCatData(response.data);
  };
  const loadMicroCat = async () => {
    const post = { subcatid: subcatid }
    console.log(post);
    const response = await axios.post("https://api.stywork.in/cat3", post);
    console.log(response.data);
    microCatList(response.data);
  };
  const savequery = async () => {
    try {
      const postSaveData = {
        microcatid: microcatid, custname: name, custMobile: mobile,
        custEmail: email, custArea: area, custPrice: totalPrice, CustCondition: condition
      }
      console.log(postSaveData);
      await axios.post('https://api.stywork.in/savequery', postSaveData)
      alert('Data Store successfully!!!!!!');
      return navigate('/');
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    loadMicroCat();
  }, []);
  const handleClickEvent = () => {
    savequery();
  }
  const getMicroForm = (val) => {
    console.log(val);
    const getval = val.split('_');
    console.log(getval[0] + ',' + getval[1]);
    setratestatus(getval[1]);
    if (getval[1] === "0") {
      setArea(0);
      setCondition("");
      setTotalPrice(0);
    }
    loadData(getval[0]);
  }
  function handleChange(evt) {
    const name = evt.target.name;
    if (name === 'Name') {
      setUsername(evt.target.value);
    }
    else if (name === 'Email') {
      setEmail(evt.target.value);
    }
    else if (name === 'Mobile') {
      setMobile(evt.target.value);
    }
    else if (name === 'Area') {
      setArea(evt.target.value);

    }

  }
  function getTotalprice(val) {
    const loadPriceDataData = async () => {
      const post = { respoid: microcatid, areaval: area, conditioval: val }
      console.log(post);
      const response = await axios.post("https://api.stywork.in/getareacost", post);
      console.log(response.data);
      var price = response.data[0].cost;
      console.log(price);
      console.log(area);
      var totalCost = price * area;
      console.log(totalCost);
      setPrice(price);
      setTotalPrice(totalCost);
      setCondition(val);
    };
    loadPriceDataData();
  }
  return (
    <>
      {/* Query section */}
      <section className="professional_section contact_section layout_padding form_section">
        <img src='../images/contener_bg.jpg' className='form_bg_design'/>
        <div className='container'>
          <div className="row" style={{ margin: "0 auto;" }}>
            <div className="col-lg-7 col-md-12 enqry_form_cont">
              <div className="detail-box">
                <h2>Post your Requirement</h2>
                {/* <p>Please provide basic details. We contact you shortlly.</p> */}
                <form action="" method="post" onSubmit={savequery}>
                    <>
                      <div>
                        <label>Name<span className='text-danger'>*</span></label>
                        <input type="text" name="Name"
                          className="form-control" id="Name" required
                          onChange={handleChange} />
                      </div>
                      <div>
                        <label>Mobile<span className='text-danger'>*</span></label>
                        <input type="text" name="Mobile"
                          className="form-control" id="Mobile" required
                          onChange={handleChange} />
                      </div>
                      <div>
                        <label>Service<span className='text-danger'>*</span></label>
                        <input type="text" name="Email"
                          className="form-control" id="Email" required
                          onChange={handleChange} />
                      </div>
                      <div>
                        <label>Address<span className='text-danger'>*</span></label>
                        <input type="text" name="Email"
                          className="form-control" id="Email" required
                          onChange={handleChange} />
                      </div>
                      <div>
                        <label>Message<span className='text-danger'>*</span></label>
                        <input type="text" name="Email"
                          className="form-control" id="txt_msg" required
                          onChange={handleChange} />
                      </div>
                    </>
                  <div>
                    <button type="button" id="btn_enqury" className='btn btn-success btn_design' onClick={handleClickEvent}>
                      Submit query</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section >
      {/* end about section */}
    </>
  );
};
export default EnqryForm;