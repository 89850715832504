import React from "react";
import { NavLink } from "react-router-dom";
const navbarAfter = () => {
  return (
    <>
    <div className="hero_area">
    {/* header section strats */}
    <header className="header_section">
      <div className="header_top">
        <div className="container-fluid">
          <div className="contact_nav">
            <a href="tel:07827990083">
              <i className="fa fa-phone" aria-hidden="true" />
              <span> Call : +91 782 799 0083</span>
            </a>
            <a href="">
              <i className="fa fa-envelope" aria-hidden="true" />
              <span> Email : support@stywork.in</span>
            </a>
          </div>
        </div>
      </div>
      <div className="header_bottom">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg custom_nav-container ">
            <a className="navbar-brand" href="/">
              <img
                src="images/company_logo.png"
                alt=""
                height={50}
                width={50}
              />
              <span>Stywork</span>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className=""> </span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ">
                <li className="nav-item"><NavLink to="/AdminDash" className={"nav-link"}>Dashboard</NavLink></li> 
              <li className="nav-item"><NavLink to="/" className={"nav-link"}>manage Categories</NavLink>
              <ul className="dropdown"> 
              <li  className="nav-item"><NavLink to="/AddCat" className={"nav-link"}>Add New Category</NavLink></li>
              <li className="nav-item"><NavLink to="/AddSubCat" className={"nav-link"}>Add New Sub Category</NavLink></li>
              <li className="nav-item"><NavLink to="/AddMicroCat" className={"nav-link"}>Add New Micro Category</NavLink></li>
              <li className="nav-item"><NavLink to="/CreateCatForm" className={"nav-link"}>Create Category form</NavLink></li>
              {/* <li className="nav-items"><NavLink to="/subcatpage">Sub Category Page</NavLink></li> */}
              <li className="nav-item"><NavLink to="/showhideCat" className={"nav-link"}>Show/ hide Category</NavLink></li> 
             </ul></li> 
             <li className="nav-item"><NavLink to="/stateList" className={"nav-link"}>Manage state</NavLink></li> 
             <li className="nav-item"><NavLink to="/cityList" className={"nav-link"}>Manage City</NavLink></li> 
             <li className="nav-item"><NavLink to="/" className={"nav-link"}>Join Us</NavLink>
             <ul className="dropdown"> 
              <li  className="nav-item"><NavLink to="/becomepartner" className={"nav-link"}>Service Man</NavLink></li>
              <li className="nav-item"><NavLink to="/becomeCaller" className={"nav-link"}>Caller</NavLink></li>
              <li className="nav-item"><NavLink to="/becomeVendor" className={"nav-link"}>Vendor</NavLink></li>
              {/* <li className="nav-item"><NavLink to="/CreateCatForm">Create Category form</NavLink></li> */}
              {/* <li className="nav-item"><NavLink to="/subcatpage">Sub Category Page</NavLink></li> */}
              {/* <li className="nav-item"><NavLink to="/showhideCat">Show/ hide Category</NavLink></li>  */}
             </ul>
             </li> 
             <li className="nav-item"><NavLink to="/viewquery" className={"nav-link"}>View Query</NavLink></li> 
             <li className="nav-item"><NavLink to="/AdminLogout" className={"nav-link"}>Logout</NavLink></li> 
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
    {/* end header section */}
  </div>
    </>
  );
};

export default navbarAfter;