import React, { useState, useEffect } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";
import axios from "axios";
const SubServices = () => {
    //const {catid} = useState("");
    const { catid } = useParams("");
    console.log(catid);
  const [SubCat, setSubcats] = useState([]);
  const loadSubCat = async (e) => {
    const post = { mastercatid: catid};
    console.log(post);
    const response = await axios.post("https://api.stywork.in/cat2", post);
    console.log(response.data);
    setSubcats(response.data);
  };
  useEffect(() => {
    loadSubCat();
  }, []);
  return (
    <>
      {/* service section */}
      <section className="service_section layout_padding">
        <div className="container ">
          <div className="heading_container heading_center">
            <h2> Our Sub Services </h2>
          </div>
          <div className="row">
            {SubCat.length > 0 && (
              SubCat.map(items => (
                <>
                  <div className="col-sm-6 col-md-4 mx-auto">
                    <div className="box ">
                      <Link to={"/EnqryFormPage/"+items.id} className='max_link'></Link>
                      <div className="img-box">
                      <img src={"../images/svgicons/subcaticons/"+items.subcatImg} alt="" />
                         </div>
                      <div className="detail-box">
                        <h5><b>{items.subcatname}</b></h5>
                        <p>
                        {items.subdisc}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )))}

          </div>
        </div>
      </section>
      {/* end service section */}
    </>

  )
}
export default SubServices;