import React from "react";

const About = () => {
    return (
        <>
            {/* about section */}
            <section className="about_section layout_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6">
                            <div className="detail-box">
                                <h2>Welcome to Our Company</h2>
                                <p>
                                Stywork is the interior & construction service providers to people who need 
                                civil and interior work requirement as individual and turnkey basis. 
                                company was started with a vision by the founders in April 2022 to bring 
                                various industries on one single platform.  We aimed to provide 99.99% 
                                consumer satisfaction, and trustworthy relationship. The company serve 
                                to market on the B2C model and offers solutions to problems of procuring 
                                fine and crafted work related to the Interior and construction on one click. 
                                Stywork is at your door step. It connects all types of Interior services.
                                </p>
                                <p>
                                Stywork is one of its kind companies in the market. The company serve to 
                                the Consumer and Service Providers in the construction and building sector. 
                                It aims to satisfy the needs of every client by helping them to cost 
                                optimizations & batter result with our service team. we assure a delivery 
                                of high-quality work. We have building technicians like plumber, electrician,
                                 false ceiling technicians, architecture and others and provides them under
                                  one roof. The company works in proper system, where it facilitates smooth 
                                  functioning between client workers. Our Company provides the best quality 
                                  service at really low cost. There is time and money both saved. Here are the 
                                  services that are provided under the linter of Stywork:
                                </p>
                            </div>
                            
                        </div>
                        <div className="col-lg-7 col-md-6">
                            <div className="img-box">
                                <img src="images/about-img.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="col-md-12"><h3>CATEGORIES</h3></div>
                        <div className="row">
                        <div className="col-md-6">
                            <ol className="list-group list-group-numbered">
                            <li className="list-group-item">Interior Design 2D &3D</li>
                            <li className="list-group-item">Modular Kitchen </li>
                            <li className="list-group-item">Modular Furniture </li>
                            <li className="list-group-item">Morden Doors and Windows</li>
                            <li className="list-group-item">False ceiling</li>
                            <li className="list-group-item">Flooring Wall Panelling</li>
                            </ol></div>
                        <div className="col-md-6"><ol>
                            <li className="list-group-item">Aluminium and Glass work</li>
                            <li className="list-group-item">Tiles- Floor and walls</li>
                            <li className="list-group-item">Paints Normal & Deco paint</li>
                            <li className="list-group-item">False Ceiling </li>
                            <li className="list-group-item">Stones and marbles </li>
                            <li className="list-group-item">Fabrication (MS)</li>
                            </ol></div></div>
                    </div>
                </div>
            </section>
            {/* end about section */}
            {/* info section */}
            <section className="info_section ">
                <div className="container">
                    <h4>Get In Touch</h4>
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="info_items">
                                <div className="row">
                                    <div className="col-md-4">
                                        <a href="">
                                            <div className="item ">
                                                <div className="img-box ">
                                                    <i className="fa fa-map-marker" aria-hidden="true" />
                                                </div>
                                                <p>Raj Nagar Extension, Ghaziabad-201017</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="">
                                            <div className="item ">
                                                <div className="img-box ">
                                                    <i className="fa fa-phone" aria-hidden="true" />
                                                </div>
                                                <p>+91 7827990083</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="">
                                            <div className="item ">
                                                <div className="img-box">
                                                    <i className="fa fa-envelope" aria-hidden="true" />
                                                </div>
                                                <p>stywork05@gmail.com</p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="social-box">
                    <h4>Follow Us</h4>
                    <div className="box">
                        <a href="">
                            <i className="fa fa-facebook" aria-hidden="true" />
                        </a>
                        <a href="">
                            <i className="fa fa-twitter" aria-hidden="true" />
                        </a>
                        <a href="">
                            <i className="fa fa-youtube" aria-hidden="true" />
                        </a>
                        <a href="">
                            <i className="fa fa-instagram" aria-hidden="true" />
                        </a>
                    </div>
                </div>
            </section>
            {/* end info_section */}
        </>

    );
};

export default About;