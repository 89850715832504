import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Heroimg from '../components/Heroimg.jsx';
import axios from "axios";
const Home = () => {
 //http://85.31.233.8:5000
  const [MainCat, setMainCat] = useState([]);
  const loadMainData = async () => {
    const response = await axios.get("https://api.stywork.in/maincat");
    console.log(response.data);
    setMainCat(response.data);
  };
  useEffect(() => {
    loadMainData();
  }, []);
  return (
    <>
      <Heroimg />
      {/* service section */}
      <section className="service_section layout_padding">
        <div className="container ">
          <div className="heading_container heading_center">
            <h2> Our Services </h2>
          </div>
          <div className="row">
            {MainCat.length > 0 && (
              MainCat.map(items => (
                <>
                  <div className="col-sm-6 col-md-4 mx-auto">
                    <div className="box ">
                      <Link to={"/subservices/"+items.id} className='max_link'></Link>
                      <div className="img-box">
                        <img src={"../images/svgicons/"+items.catImg} alt="" />
                      </div>
                      <div className="detail-box">
                        <h5><b>{items.catname}</b></h5>
                        <p>
                          {items.discription}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )))}

          </div>
        </div>
      </section>
    </>
  )
}
export default Home;