import React from "react";

const Portfolio = () => {
    return (
        <>
            {/* about section */}
            <section className="about_section layout_padding">
                <h1 className="text-center">Our Portfolio</h1>
                <div class="container">
        <div class="col-12">
        <div class="row">
            <a href="/images/portfolio/Interior (1).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (1).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (2).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (2).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (3).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (3).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (4).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (4).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (5).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (5).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (6).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (6).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (7).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (7).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (8).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (8).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (9).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (9).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (10).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (10).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (11).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (11).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (12).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (12).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (13).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (13).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (14).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (14).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (15).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (15).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (16).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (16).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (17).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (17).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (18).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (18).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (19).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (19).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (20).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (20).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (21).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (21).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (22).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (22).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (23).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (23).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (24).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (24).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (25).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (25).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (26).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (26).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (27).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (27).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (28).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (28).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (29).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (29).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (30).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (30).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (31).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (31).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (32).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (32).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (33).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (33).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (34).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (34).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (35).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (35).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (36).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (36).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (37).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (37).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (38).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (38).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (39).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (39).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (40).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (40).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (41).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (41).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (42).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (42).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (43).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (43).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (44).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (44).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (45).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (45).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (46).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (46).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (47).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (47).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (48).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (48).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (49).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (49).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (50).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (50).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (51).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (51).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (52).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (52).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (53).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (53).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (54).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (54).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (55).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (55).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (56).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (56).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (57).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (57).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (58).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (58).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (59).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (59).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (61).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (61).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (62).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (62).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (63).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (63).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (64).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (64).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (66).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (66).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (67).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (67).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (68).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (68).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (69).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (69).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (70).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (70).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (71).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (71).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (72).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (72).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (73).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (73).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (74).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (74).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (75).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (75).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (76).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (76).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (77).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (77).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (78).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (78).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (79).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (79).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (80).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (80).jpg" class="img-fluid card"/>
            </a>
            <a href="/images/portfolio/Interior (81).jpg" data-toggle="lightbox" data-gallery="example-gallery" target="_blank" class="col-lg-3 col-md-4 col-6 my-3">
                <img src="/images/portfolio/shortImg/Interior (81).jpg" class="img-fluid card"/>
            </a>
        </div>
    </div>
</div>
            </section>
            {/* end about section */}
        </>
    );
};

export default Portfolio;