import React from "react";
import { NavLink } from "react-router-dom";
const Heroimg = () => {
  const CalculateCost= (e)=>{
    console.log('new data');
  }
  const handleClickEvent=()=>{
    //console.log('handal hero data');
    window.location.href='/services';
  }
  return (
<>
 {/* Query section */}
 <section className="professional_section contact_section layout_padding form_section">
        <div className='container'>
          <div className="row" style={{ margin: "0 auto;" }}>
            <div className="col-md-12 enqry_form_cont">
              <div className="col-md-12"></div>
              <form>
              <div className="row">
             <div className="col-md-3">
             <select className="form-control">
<option value={0}>Select States</option>
<option value={0}>Ghaziabad</option>
             </select>
             </div>
             <div className="col-md-7">
             <h4 className="text-center">Fast, FREE way to get experts.</h4>
              <input type="text" id="search_box" name="search_box" className="form-control state_select"
             placeholder="Find your service here">
              </input></div>
             <div className="col-md-2"><input type="button" 
             id="btn_search" name="btn_search" value="Search" className="btn btn-block search_btn_design"
             onclick={handleClickEvent}/></div>
             </div>
             </form>
            </div>
          </div>
        </div>
      </section >
      {/* end about section */}
</>
  )
}
export default Heroimg;