import React from "react";
import { NavLink } from "react-router-dom";
const Navbar = () => {
  return (
<>
  <div className="hero_area">
    {/* header section strats */}
    <header className="header_section">
      <div className="header_top">
        <div className="container-fluid">
          <div className="contact_nav">
            <a href="tel:07827990083">
              <i className="fa fa-phone" aria-hidden="true" />
              <span> Call : +91 782 799 0083</span>
            </a>
            <a href="">
              <i className="fa fa-envelope" aria-hidden="true" />
              <span> Email : support@stywork.in</span>
            </a>
          </div>
        </div>
      </div>
      <div className="header_bottom">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg custom_nav-container ">
            <a className="navbar-brand" href="index.html">
              <img
                src="images/stywork_logo.jpeg"
                alt=""
                height={50}
                width={50}
              />
              <span> &nbsp;Stywork</span>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className=""> </span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ">
                <li className="nav-item active">
                <NavLink to="/" className={"nav-link"}>Home</NavLink>
                </li>
                <li className="nav-item">
                <NavLink to="/about" className={"nav-link"}>About Us</NavLink>
                </li>
                <li className="nav-item">
                <NavLink to="/services" className={"nav-link"}>Our Services</NavLink>
                </li>
                <li className="nav-item">
                <NavLink to="/portfolio" className={"nav-link"}>Portfolio</NavLink>
                </li>
                <li className="nav-item">
                <NavLink to="/ourwork" className={"nav-link"}>Our Work</NavLink>
                </li>
                {/* <li className="nav-item">
                <NavLink to="/adminlogin" className={"nav-link"}>Login</NavLink>
                </li> */}
                <li className="nav-item">
                <NavLink to="/contact" className={"nav-link"}>Contact Us</NavLink>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
    {/* end header section */}
  </div>
</>

  );
};

export default Navbar;